import React from 'react';
import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

function TwitterIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="76" height="76" viewBox="0 0 76 76" fill="none" {...props}>
      <rect width="76" height="76" rx="38" className="fill-secondary" />
      <path
        d="M31.046 58.045C47.899 58.045 57.114 44.08 57.114 31.977C57.114 31.578 57.114 31.179 57.095 30.799C58.881 29.507 60.439 27.892 61.674 26.049C60.04 26.771 58.273 27.265 56.411 27.493C58.311 26.353 59.755 24.567 60.439 22.42C58.672 23.465 56.715 24.225 54.625 24.643C52.953 22.857 50.578 21.755 47.937 21.755C42.883 21.755 38.779 25.859 38.779 30.913C38.779 31.635 38.855 32.338 39.026 33.003C31.407 32.623 24.662 28.975 20.14 23.427C19.361 24.776 18.905 26.353 18.905 28.025C18.905 31.198 20.52 34.01 22.99 35.644C21.489 35.606 20.083 35.188 18.848 34.504C18.848 34.542 18.848 34.58 18.848 34.618C18.848 39.064 22.002 42.75 26.201 43.605C25.441 43.814 24.624 43.928 23.788 43.928C23.199 43.928 22.629 43.871 22.059 43.757C23.218 47.405 26.6 50.046 30.609 50.122C27.474 52.573 23.522 54.036 19.228 54.036C18.487 54.036 17.765 53.998 17.043 53.903C21.052 56.525 25.878 58.045 31.046 58.045Z"
        className="fill-primary"
      />
    </SvgIcon>
  );
}

export default TwitterIcon;
